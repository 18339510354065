/* You can add global styles to this file, and also import other style files */

.heightLimit {
    
    /* height:100vh; */
}
body {display: block;
    /* height:100vh; */
}

html {
    background-color: #24384B;
}
@media only screen and (max-width : 768px) {


.heightLimit {
        background-color: #24384B;
    }

}